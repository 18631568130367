.container:before {
  display: table;
  content: " ";
}

// ec generic classes
.ec-rm-margin {
  margin: 0px !important;
}

.ec-margin-bottom-20 {
  margin-bottom: 20px;
}

.ec-pt-1{
  padding-top: 1rem;
}

// ec generic classes ends

.phone-link {
  font-weight: 800;
  font-size: 1.75rem;
}

.ec-footer-phone {
  font-size: 1.68rem;
  color: $primary-theme-font-color !important;
}

.ec-newsletter-heading {
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 600;
  color: $primary-theme-font-color !important;
}

.ec-newsletter-subheading {
  font-size: 0.8125rem;
  color: $primary-theme-font-color !important;
}

.ec-primary-background {
  background-color: $primary-theme-color;
}

.ec-footer-image {
  width: 200px;
}

.ec-width-100 {
  width: 100%;
}

#contact form#contactForm input[type="text"],
#contact form#contactForm input[type="email"],
#contact form#contactForm input[type="tel"] {
  min-height: 52px;
  padding: 0 0 0 28px;
  font-size: 16px;
  color: #5e629c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#contact form#contactForm textarea {
  padding: 15px 0 0 28px;
  font-size: 16px;
  color: #5e629c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#contact form#contactForm input::placeholder,
#contact form#contactForm textarea::placeholder {
  color: #6c6c6c;
  font-weight: 500;
}

#contact form#contactForm .form-group {
  margin-bottom: 30px;
}

// ec organisation section

.ec-organisation{
  padding-top:3.5rem;
}

.ec-organisation .description{
  font-size: 1.2rem;
  color: #A9A9A9;
  font-weight: 300;
}

.ec-organisation .ec-org-widget{
  background-color: #eee;
  height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ec-organisation .ec-org-widget .ec-widget-icon{
  text-align: center;
}

.ec-organisation .ec-org-widget .ec-widget-icon i{
  font-size: 2.5rem;
  margin-bottom: 1.3rem;
  color: #706f6f;
}
// ec organisation section ends



// ec landing quote section

.ec-landing-quote {
  padding: 3rem 0rem;
  background-color: #ffcc5a;
}

.ec-landing-quote h3 {
  margin: 0px !important;
  line-height: 4rem;
}

// ec landing quote section ends

// ec world map section

.ec-world-map .ec-world-map-info{
  text-align: center;
  color: #fff;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.ec-world-map .ec-world-map-img{
  padding-bottom: 4rem;
}

// ec world map section ends


// ec testimonials
.ec-testimonials{
  padding:4rem 0rem;
}

.ec-testimonials .item .single-testimonial-style-two .client-info-box {
  margin-top: 30px;
}

.ec-testimonials .item{
  margin: 10px;
  padding: 25px;
  border: 1px solid #eee;
  border-radius: 16px;
  box-shadow: 0px 0px 6px #eee;
}

.ec-testimonials .single-testimonial-style-two .client-info-box .img-box {
  width: 90px;
}

.ec-testimonials .single-testimonial-style-two p {
  position: relative;
  color: #888;
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
}

.single-testimonial-style-two p i.fa-quote-left {
  top: 0;
  left: 0;
}

.single-testimonial-style-two p i.fa-quote-right {
  bottom: 0;
  right: 0;
}

.single-testimonial-style-two p i.fas {
  color: #003990;
  font-size: 17px;
  position: absolute;
}

.ec-testimonials .single-testimonial-style-two .client-info-box .img-box, .single-testimonial-style-two .client-info-box .text-box h3{
  font-weight: 600;
}

.ec-testimonials .single-testimonial-style-two .client-info-box .img-box, .single-testimonial-style-two .client-info-box .text-box {
  display: table-cell;
  vertical-align: middle;
}

.ec-testimonials .single-testimonial-style-two .client-info-box .img-box > img {
  width: 100%;
  border-radius: 50%;
  border: 5px solid #eee;
}
// ec testimonials ends


// footer styles

.contact-info-style-one {
  padding: 2.5rem 0rem;
}
.contact-info-style-one .title h3 {
  font-family: inherit;
  font-weight: 400;
  color: #ffffff;
  font-size: 1.7rem;
}

.contact-info-style-one .title p {
  font-size: 1rem;
  line-height: 28px;
  color: #ffffff;
  letter-spacing: 0.01em;
  margin-top: 8px;
}

.contact-info-style-one .contact-infos {
  padding: 21px 0;
  padding-left: 70px;
}

.contact-info-style-one .contact-infos .single-contact-infos {
  display: inline-block;
  vertical-align: middle;
}

.contact-info-style-one
  .contact-infos
  .single-contact-infos
  + .single-contact-infos {
  margin-left: 65px;
}

.contact-info-style-one .contact-infos .single-contact-infos .icon-box {
  width: 33px;
}
.contact-info-style-one .contact-infos .single-contact-infos .icon-box i {
  color: #fff;
  font-size: 1.7rem;
}
.contact-info-style-one .contact-infos .single-contact-infos .icon-box,
.contact-info-style-one .contact-infos .single-contact-infos .text-box {
  display: table-cell;
  vertical-align: middle;
}

.contact-info-style-one .contact-infos .single-contact-infos .text-box {
  padding-left: 10px;
}

.contact-info-style-one .contact-infos .single-contact-infos .text-box h3,
.contact-info-style-one .contact-infos .single-contact-infos .text-box p {
  margin: 0;
}

.contact-info-style-one .contact-infos .single-contact-infos .text-box h3 {
  font-size: 1.3rem;
  font-family: inherit;
  color: #ffffff;
  font-weight: 400;
}

.contact-info-style-one .contact-infos .single-contact-infos .text-box p,
.contact-info-style-one
  .contact-infos
  .single-contact-infos
  .text-box
  .contact-link {
  font-size: 1.5rem;
  font-family: inherit;
  color: #ffffff;
  font-weight: 600;
}

footer.site-footer .bottom-footer .container {
  border-top: 1px solid #e1e1e1;
  padding-top: 28.5px;
  padding-bottom: 28.5px;
}

footer.site-footer .bottom-footer p {
  margin: 0;
  font-size: 0.95rem;
}

footer.site-footer .footer-widget p {
  font-size: 15px;
  color: #888888;
  line-height: 25px;
}

footer.site-footer .footer-widget h3 {
  font-size: 1.2rem;
  font-weight: 600;
}

footer.site-footer .footer-widget p,
footer.site-footer .footer-widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1rem;
}

footer.site-footer .footer-widget .title {
  margin-bottom: 10px;
}

footer.site-footer .section-divider {
  border-right: 1px solid #e1e1e1;
}

footer.site-footer .footer-widget ul.footer-list li {
  display: block;
  line-height: 1.6rem;
  color: #888888;
}

footer.site-footer .main-footer {
  padding-top: 50px;
  padding-bottom: 45px;
}

footer.site-footer .footer-widget.about-widget {
  position: relative;
  bottom: 50px;
}

.spacer {
  clear: both;
}

.ng2-custom-carousel-container {
  width: 100%;
  margin: auto;
}

.ng2-custom-carousel-element {
  padding: 0;
  width: 300px;
  height: 300px;
  background-color: crimson;
  color: #fff;
  font-size: 50px;
  line-height: 300px;
  text-align: center;
}

@media (max-width: 1200px) {
  .ec-landing-quote .ec-lq-sub-container div{
    width:100%;
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .contact-info-style-one
    .contact-infos
    .single-contact-infos
    + .single-contact-infos {
    margin-left: 20px;
  }
  .contact-info-style-one .contact-infos {
    padding-left: 0;
  }
}

@media (max-width: 768px){
  footer.site-footer .bottom-footer .container div {
    width:100%;
    text-align: center;
  }

  .ec-landing-quote {
    padding: 2.5rem 0rem;
  }
  
  .ec-landing-quote h3 {
    margin: 0px !important;
    line-height: 2.5rem;
  }
}

@media (max-width: 480px) {
  .contact-info-style-one
    .contact-infos
    .single-contact-infos
    + .single-contact-infos {
    margin-left: 0;
    margin-top: 30px;
  }

  .contact-info-style-one .contact-infos .single-contact-infos {
    display: block;
  }
}

// footer style ends
