.ec-top-header{
    background: #003990;
    color: #fff;
}


.ec-top-header-content{
    text-align: center;
    padding: 13px 10px;
    font-size: 16px;
    margin:0px!important;
}

.s-showcase .showcase-text {
    position: relative;
    padding-top: 39.1875rem;
    padding-bottom: 4.1875rem;
    text-align: center;
    color: #fff;
    z-index: 3;
}

.s-showcase .showcase-text h1.showcase-heading {
    text-align: center;
    line-height: 1.36667;
    font-size: 1.875rem;
    margin-bottom: 0;
    font-weight: 600;
    color: #fff;
    text-shadow: 0 2px 4px rgb(0 0 0 / 75%);
}

.s-showcase .showcase-text h2.showcase-subheading {
    text-align: center;
    line-height: 2.05;
    font-size: 1.25rem;
    margin-bottom: 0;
    font-weight: 400;
    color: #fff;
    text-shadow: 0 2px 4px rgb(0 0 0 / 75%);
}

@media (min-width: 992px){
    .s-showcase .showcase-text h1.showcase-heading {
        font-size: 3.125rem;
        margin-bottom: 1.25rem;
    }
}

@media (max-width: 768px){
    .s-showcase .showcase-text h1.showcase-heading {
        text-align: center;
        line-height: 1.36667;
        font-size: 1.875rem;
        margin-bottom: 0;
        font-weight: 600;
        color: #fff;
        text-shadow: 0 2px 4px rgb(0 0 0 / 75%);
    }
}

@media (min-width: 992px){
    .s-showcase .showcase-text h2.showcase-subheading {
        line-height: 1.6;
        font-size: 1.25rem;
        margin-bottom: 1rem;
    }
}

@media (max-width: 768px){
    .s-showcase .showcase-text h2.showcase-subheading {
        text-align: center;
        line-height: 2.05;
        font-size: 1.25rem;
        margin-bottom: 0;
        font-weight: 400;
        color: #fff;
        text-shadow: 0 2px 4px rgb(0 0 0 / 75%);
    }
}



// .wrapper-home::before{
//     // background-color: ;
//     height:300px;
//     background-image: linear-gradient(
// 0deg
// ,rgba(0,0,0,.75) 6%,transparent 48%);
//     width: 100%;
// }

@media only screen and (max-width: 780px) {
    .ec-top-header{
        display: none;
    }

    .navbar.navbar-expand-lg{
        top: 0px!important;
    }

    .navbar-nav .nav-item .nav-link.active-link p::after{
        display: none;
    }

    .em-collapse-top-content{
        display: block!important;
    }
  }

  @media only screen and (max-width: 991px) {
    .navbar-nav .nav-item .nav-link.active-link p::after{
        display: none;
    }
    .navbar-nav .nav-item .nav-link p{
        color: #fff!important;
    }
    .navbar-nav .nav-item .nav-link.active-link p{
        color: #fff!important;
        border-bottom: 2px solid #fff;
    }
    
  }

  @media only screen and (min-width : 991px) {
    .classname {
        /* styles here */
    }

    .em-collapse-top-content{
        display: none!important;
    }
}

.navbar{
    padding-top: $navbar-padding-base;
    padding-bottom: $navbar-padding-base;
    min-height: 53px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);

    a{
        vertical-align: middle;

        &:not(.btn):not(.dropdown-item){
            color: $white-color;
        }
    }

    p{
        display: inline-block;
        margin: 0;
        line-height: 21px;
    }

    .navbar-nav{
        &.navbar-logo{
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 49px;
            top: -4px;
        }

        .nav-link.btn{
            padding: $padding-btn-vertical $padding-btn-horizontal;

            &.btn-lg{
                padding: $padding-large-vertical $padding-large-horizontal;
            }

            &.btn-sm{
                padding: $padding-small-vertical $padding-small-horizontal;
            }
        }

        .nav-link{
            position: relative;
            padding: 0.5rem 0rem!important;
            margin-left: 15px;
        }

        p{
            margin: 0px 0px;
            color: #003990 !important;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.5em;
            line-height: 1.5em;
            padding: 15px 0;
        }

        .nav-link:not(.btn){
            text-transform: uppercase;
            font-size: $font-size-mini;
            padding: $padding-base-vertical $padding-base-horizontal;
            line-height: $line-height-nav-link;

            i.fab + p,
            i.now-ui-icons + p{
                margin-left: 3px;
            }

            i.fab,
            i.now-ui-icons{
                font-size: 18px;
                position: relative;
                top: 3px;
                text-align: center;
                width: 21px;
            }

            i.now-ui-icons{
                top: 4px;
                font-size: 16px;
            }

            &.profile-photo{
                .profile-photo-small{
                    width: 27px;
                    height: 27px;
                }
            }

            &.disabled{
                opacity: .5;
                color: $white-color;
            }
        }

        .nav-item.active .nav-link:not(.btn),
        .nav-item .nav-link:not(.btn):focus,
        .nav-item .nav-link:not(.btn):hover,
        .nav-item .nav-link:not(.btn):active{
            background-color: $opacity-2;
            border-radius: $border-radius-small;
        }
    }

    // Custom CSS

    .ec-logo{
        width: 210px;
    }

    .navbar-nav .nav-item .nav-link p::after {
        content: '';
        position: absolute;
        width: 0%;
        height: 3px;
        background:  #003990;
        left: 0;
        bottom: 10px;
        transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
    }
    
    .navbar-nav .nav-item .nav-link.active-link p::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background:  #003990;
        left: 0;
        bottom: 10px;
    }
    
    .navbar-nav .nav-item.active>a::after, .navbar-nav .nav-item:hover>.nav-link p::after {
        width: 100%;
    }

    // Custom CSS Ends

    .logo-container{
        width: 27px;
        height: 27px;
        overflow: hidden;
        margin: 0 auto;
        border-radius: 50%;
        border: 1px solid transparent;
    }

    .navbar-brand{
        text-transform: uppercase;
        font-size: $font-size-small;
        padding-top: $padding-base-vertical;
        padding-bottom: $padding-base-vertical;
        // line-height: $line-height-nav-link;
        line-height: 2rem;
    }

    .navbar-toggler{
        width: 37px;
        height: 27px;
        outline: 0;
        cursor: pointer;

        &.navbar-toggler-left{
            position: relative;
            left: 0;
            padding-left: 0;
        }

        &:hover{
            & .navbar-toggler-bar.bar2{
                width: 22px;
            }
        }
    }

    .button-dropdown{
        .navbar-toggler-bar:nth-child(2){
            width: 17px;
        }
    }

    &.navbar-transparent{
        background-color: $transparent-bg !important;
        box-shadow: none;
        color: $white-color;
        padding-top: 20px;
    }

    &.bg-white:not(.navbar-transparent){
        a:not(.dropdown-item){
            color: $default-color;

            &.disabled{
                opacity: .5;
                color: $default-color;
            }
        }

        .button-bar{
            background: $default-color;
        }

        // .nav-item.active .nav-link:not(.btn),
        // .nav-item .nav-link:not(.btn):focus,
        // .nav-item .nav-link:not(.btn):hover,
        // .nav-item .nav-link:not(.btn):active{
        //     background-color: $opacity-gray-3;
        // }

        .logo-container{
            border: 1px solid $default-color;
        }
    }
}

.bg-default{
    background-color: $default-color !important;
}

.bg-primary{
    background-color: $primary-color !important;
}

.bg-info{
    background-color: $info-color !important;
}

.bg-success{
    background-color: $success-color !important;
}

.bg-danger{
    background-color: $danger-color !important;
}

.bg-warning{
    background-color: $warning-color !important;
}

.bg-white{
    background-color: $white-color !important;
}
